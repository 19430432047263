/// <reference path='./../../../../typings/tsd.d.ts'/>

export class ResultsController {
	static $inject = ['$location', '$scope', '$state', '$log', '$http', '$compile', 'dbRequest', 'switchBoard'];

	public vm;

	constructor(private $location, $scope, $state, private $log, $http, $compile, dbRequest, switchBoard) {
		this.vm = $scope;

		$state.transitionTo('results');
		$scope.search = function() {
			$state.transitionTo('home', {
				searchText: $scope.searchText
			}, {
					reload: true,
					notify: true
        });
		}

		// -----------------------------------------;
		// Update page title and body class;
		// -----------------------------------------;
		// Set our view title directly to avoid
		// issues with earlier IE versions;
		window.document.title = 'Results | EMEA Neo4j SuperSearch';

		// Update our body class without Angularjs;
		// Use jQuery to remove (best speed), and
		// standard Javascript to add (best perf again);
		$('body').removeClass();
		document.body.classList.add('results');


		// Neo4j Authorization;
		// TODO: Move this to config file;
		var auth = 'Basic ' + btoa('neo4j:mnbv!456');

		// -----------------------------------------;
		// Search Domain Filter;
		// -----------------------------------------;
		// Primary collection to search; defaults to Contacts;
		this.vm.findTypes =
			[
				{
					id: 0,
					value: 'Company',
					phrase: 'Companies',
					icon: 'ico-company',
					find: 'company'
				},
				{
					id: 1,
					value: 'Company_Contact',
					phrase: 'Contacts',
					icon: 'ico-contact',
					find: 'contact'
				}
			];


		if (!_.isUndefined(switchBoard.getFindType())) {
			var res = [];

			this.vm.findTypes.forEach(
				function(o) {
					if (o.value == switchBoard.getFindType()) res.push(o);
				});

			this.vm.selectedFindType = res[0];
		} else {
			this.vm.selectedFindType = this.vm.findTypes[1];
		}

		// User Actions;
		this.vm.status = {
			isopen: false
		};

		this.vm.toggled = function(open) {
			$log.log('Dropdown is now: ', open);
		};

		this.vm.toggleDropdown = function($event) {
			$event.preventDefault();
			$event.stopPropagation();
			this.vm.status.isopen = !this.vm.status.isopen;
		};

		this.vm.userActions = [
			{
				text: 'User manual',
				url: 'https://supersearch.eu.joneslanglasalle.com/Content/manuals/bp_uk_manual.pdf'
			}
		];

		// -----------------------------------------;
		// Country Filter;
		// -----------------------------------------;
		this.vm.ddSelectOptions = [
			{
				id:    0,
				text: 'All Countries',
				value: 'All',
				cssClass: 'glyph-cdd-all',
				status: 'Search results will include companies from all countries'
			},
			{
				id:    1,
				text: 'United Kingdom',
				value: 'United Kingdom',
				cssClass: 'glyph-cdd-uk',
				status: 'Search results will include United Kingdom companies only'
			}
		];

		if (_.isUndefined(switchBoard.findCountry)) {
			this.vm.ddSelectSelected = this.vm.ddSelectOptions[1];
		} else {
			this.vm.ddSelectSelected = this.vm.ddSelectOptions[switchBoard.findCountry];
		}

		// -----------------------------------------;
		// Typeahead;
		// -----------------------------------------;
		function idIndex(a, id) {
			for (var i = 0; i < a.length; i++) {
				if (a[i].id == id) {
					return i;
				}
			}
			return null;
		}

		$scope.filters = {};

		var typeaheadData;

		$scope.$watchGroup(
			[
				'selectedFindType.value',
				'ddSelectSelected.id',
				'selected'
			],
			function(newValues, oldValues, scope) {
				// Update switchBoard;
				switchBoard.setFindType(newValues[0]);
				switchBoard.setFindCountry(newValues[1]);
				switchBoard.setFindText(newValues[2]);
				console.log('printSettings: ' + switchBoard.getFindType() + ', ' + switchBoard.getFindText() + ', ' + switchBoard.getFindCountry());

				// Update dependencies;
				updateTypeAheadList(newValues[0], newValues[1], newValues[2]);
			});

		function updateTypeAheadList(anEntityType, aCountry, aTextInput) {
			var qry = constructTypeaheadQuery(anEntityType, aCountry, aTextInput);

			fetchTypeaheadList(qry);
		}

		function constructTypeaheadQuery(anEntityType, aCountrySelId, aTextInput) {
			var pQuery
				, pInput = getFormattedParamFor(aTextInput)
				, countryName = getFormattedCountryParamFor(aCountrySelId);

			// Construct Companies query;
			if (anEntityType === 'Company') {
				pQuery = { 'statements': [{ 'parameters': { 'cntry': countryName, 'anInp': pInput }, 'statement': 'MATCH (c:Company) WHERE (c.Country =~ {cntry}) AND (c.CompanyName =~ {anInp}) RETURN c ORDER BY c.CompanyName LIMIT 10', 'resultDataContents': ['graph'] }] };
			}
			else {
				// Construct Company Contacts query;
				pQuery = { 'statements': [{ 'parameters': { 'cntry': countryName, 'anInp': pInput }, 'statement': 'MATCH (cc:Company_Contact) WITH (cc.LastName + ", " + cc.FirstName) as ContactName WHERE ContactName=~{anInp} RETURN ContactName ORDER BY ContactName UNION MATCH (jll:JLL_Contact) WITH (jll.LastName + ", " + jll.FirstName) as ContactName WHERE ContactName=~{anInp} RETURN ContactName ORDER BY ContactName', 'resultDataContents': ['row'] }] };
			}

			return pQuery;
		}
		function createTypeaheadForContact(aDataResponse) {
			var names = [];

			aDataResponse.data.results[0].data.forEach(function(tbl) {
				names.push({ name: tbl.row[0] });
			});

			$scope.states = names;
		}

		function createTypeaheadForFindType(aDataResponse) {
			var nodes = []
			  , links = []
			  , names = [];

			aDataResponse.data.results[0].data.forEach(function(row) {
				row.graph.nodes.forEach(function(n) {
					if (idIndex(nodes, n.id) == null && n.labels[0] === 'Company') {
						// Create Company typeahead list;
						names.push(
							{
								id: n.id,
								type: n.labels[0],
								name: n.properties.CompanyName
							});
					} else {
						// Create Contacts typeahead list;
						names.push(
							{
								id: n.id,
								type: n.labels[0],
								//name: n.properties.LastName + ', ' + n.properties.FirstName,
								name: n.properties.ContactName
							});
					}
				});
			});
			console.log(names);
			$scope.states = names;
			//return $scope.states;
		}

		// Fetch TypeAhead List for either company names or company contacts;
		function fetchTypeaheadList(aQuery) {
			return dbRequest.getData(aQuery).then(
				function(dataResponse) {
					console.log('Promise was fulfilled');
					// Format and return data;
					return ($scope.selectedFindType.value === 'Company') ? createTypeaheadForFindType(dataResponse) : createTypeaheadForContact(dataResponse);
				},
				function(error) {
					// The Promise was rejected.
					console.log(error + ': Promise was rejected');
					return error;
				});
		}

		//$scope.selected = undefined;
		if (!_.isUndefined(switchBoard.getFindText())) {
			$scope.selected = switchBoard.getFindText();
		} else {
			$scope.selected = undefined;
		}

		// Hide country suggestion menu on click;
		this.vm.countryClick = function() {
			$('.dropdown-menu').addClass('ng-hide');
		}


		// -----------------------------------------;
		// Tag Inputs;
		// -----------------------------------------;
		// Source System;
		this.vm.tagsSourceSystem = getSourceSystemTags();

		function getSourceSystemTags() {
			var tags;
			if ($scope.ddSelectSelected.value == 'United Kingdom') {
				tags = [{ text: 'Client First (1,618)' }]
			}
			else {
			}
			return tags;
		}

		this.vm.loadSourceTags = function(query) {
			var tagList = [
				{
					text: 'Client First (473,204)'
				},
				{
					text: 'Argo (DE) (122,922)'
				},
				{
					text: 'Area (FR) (5,653)'
				}
			];

			//return $http.get('/tagsSourceSystem?query=' + query);
			return tagList;
		};

		// Region;
		this.vm.tagsRegion = getRegionTags();

		function getRegionTags() {
			var tags;
			if ($scope.ddSelectSelected.value == 'United Kingdom') {
				tags = [{ text: 'EMEA (15,475)' }]
			}
			return tags;
		}

		this.vm.loadRegionTags = function(query) {
			var tagList = [
				{
					text: 'Americas (20,861)'
				},
				{
					text: 'AsiaPac (12,771)'
				},
				{
					text: 'EMEA (15,475)'
				}
			];

			return tagList;
		};

		// Country;
		this.vm.tagsCountry = getCountryTags();

		function getCountryTags() {
			var tags;
			if ($scope.ddSelectSelected.value == 'United Kingdom') {
				tags = [{ text: 'United Kingdom' }]
			}
			return tags;
		}

		this.vm.loadCountryTags = function() {
			var qry: any = { 'statements': [{ 'statement': 'MATCH (c:Company) RETURN DISTINCT c.Country as countryName ORDER BY c.Country', 'resultDataContents': ['row'] }] };

			return loadAutoSuggestFor(qry);
		}

		// Industry;
		this.vm.tagsIndustry = '';

		this.vm.loadIndustryTags = function() {
			var qry: any = { 'statements': [{ 'statement': 'MATCH (c:Company) RETURN DISTINCT c.Industry ORDER BY c.Industry', 'resultDataContents': ['row'] }] };

			return loadAutoSuggestFor(qry);
		}

		// CompanyType;
		this.vm.tagsCompanyType = '';

		this.vm.loadCompanyTypeTags = function() {
			var qry: any = { 'statements': [{ 'statement': 'MATCH (c:Company) RETURN DISTINCT c.CompanyType ORDER BY c.CompanyType', 'resultDataContents': ['row'] }] };

			return loadAutoSuggestFor(qry);
		}

		// -----------------------------------------;
		// Helper functions for Neo4j queries;
		// -----------------------------------------;
		function getFormattedParamFor(aStr) {
			// -------------------------------------------------;
			// Return a string formatted for use in a Neo4j
			// search parameter regular expression;
			// -------------------------------------------------;
			return (!aStr) ? '.*' : ('(?i).*' + aStr + '.*');
		}

		function getFormattedCountryParamFor(aCountrySelId) {
			// -------------------------------------------------;
			// Given a country selection option value, find the
			// string name and then return a string formatted
			// for a Neo4j search parameter regular expression;
			// -------------------------------------------------;
			if (aCountrySelId === 0 || _.isUndefined(aCountrySelId)) {
				// Search all countries;
				return '.*'
			} else {
				// Search a specific country;
				return '(?i).*' + $scope.ddSelectOptions[aCountrySelId].value + '.*';
			}
		}

		function loadAutoSuggestFor(aQuery) {
			// -------------------------------------------------;
			// Return a set of Tags Input autosuggest data;
			// -------------------------------------------------;
			return dbRequest.getData(aQuery).then(
				function(dataResponse) {
					//console.log('Promise was fulfilled');
					// Format and return data;
					return createTagListFor(dataResponse);
				},
				function(error) {
					// The Promise was rejected.
					console.log(error + ': Promise was rejected');
					return error;
				});
		}

		function createTagListFor(aDataResponse) {
			// -------------------------------------------------;
			// Tags Input autosuggest data response formatting;
			// -------------------------------------------------;
			var res = [];

			aDataResponse.data.results[0].data.forEach(
				function(tbl) {
					var obj = {
						text: tbl.row[0]
					}
					res.push(obj);
				});

			return res;
		}


		// -----------------------------------------;
		// D3 Chart;
		// -----------------------------------------;
		var selID;

		function constructChartQuery() {
			var aCountry = '(?i).*' + 'United Kingdom' + '.*',
				pInput = '(?i).*' + 'Adwe' + '.*';

			//return { 'statements': [{ 'parameters': { 'aCntry': aCountry, 'anInp': pInput }, 'statement': 'MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact)<-[m:KNOWS]-(jll:JLL_Contact) WHERE c.CompanyName =~ {anInp} AND c.Country =~ {aCntry} RETURN r,m LIMIT 100', 'resultDataContents': ['graph'] }] };

			return { 'statements': [{ 'parameters': { 'aCntry': aCountry, 'anInp': pInput }, 'statement': 'MATCH (c:Company {CompanyID: 11841630})<-[r:WORKS_AT]-(cc:Company_Contact) OPTIONAL MATCH (cc)<-[m:KNOWS]-(jll:JLL_Contact) OPTIONAL MATCH (c)-[q:JLL_CONTACT_FOR_COMPANY]->() RETURN c, r, COLLECT(cc), m, q LIMIT 100', 'resultDataContents': ['graph'] }] };
		}

		this.vm.showDirectedGraph = function() {
			// Note: Typescript expects a very specific order for Ajax call;
			drawDirectedGraph();
		}

		function drawDirectedGraph() {
			// TODO: Add legend;
			// TODO: Move to a directive;
			// TODO: Add search box to search within graph;
			// TODO: Add node pinning functionality;
			// TODO: User change color;
			// TODO: Add photos (JLL properties only!) & pick just a few to show for a demo;
			// TODO: Put version out on webserver for Venu / Sinhura to test;
			// TODO: Add zoom in/out (use http://bl.ocks.org/eyaler/10586116, but better. Also see http://bl.ocks.org/benzguo/4370043 and http://jsfiddle.net/JSDavi/qvco2Ljy/ and https://gist.github.com/pbeseda/9899420; also https://github.com/d3/d3-zoom)
			// TODO: Add fisheye tool option (https://bost.ocks.org/mike/fisheye/);
			// TODO: Isolate node groups for JLL Contact;

			//Creating graph object
			var graph
			  , nodes = []
			  , links = []
			  , res:any;

			function createGraph(aContainerId) {
				// -----------------------------------------;
				// Initial definitions;
				// -----------------------------------------;
				// Calculate layout dimensions;
				var margin =
					{
						top: 20,
						right: 20,
						bottom: 20,
						left: 20
					},
					width = $('#graph').width() - margin.left - margin.right,
					height = $('#graph').height() - margin.top - margin.bottom,
					viewbox = '0 0 ' + $('#graph').width() + ' ' + $('#graph').height();

				// Standard link distance;
				var linkDistance = 150;

				// Set layout;
				var force = d3.layout.force();

				// Set force specifications;
				// force.charge(-820)
				// 	.linkDistance(linkDistance)
				// 	.size([width, height])
				// 	.linkStrength(0.1)
				// 	.start();

				// Standard node radii;
				var nodeRadius = 25
				  , compRadius = 37;

				// Mouse event vars
				var selected_node = null,
					selected_link = null,
					mousedown_link = null,
					mousedown_node = null,
					mouseup_node = null;

				// Define detail summary tooltip;
				var sumTip = d3.select('body')
					.append('div')
					.attr('id','summaryTip')
					.attr('class', 'tooltip')
					.style('opacity', 0);

				// -----------------------------------------;
				// Setup svg container;
				// -----------------------------------------;
				// Adjust width here to fit parent container without margins;
				var outer = d3.select('#graph').html('').append('svg')
					// Dimensions;
					.attr('width', (width + margin.left + margin.right) + 'px')
					.attr('height', (height + margin.top + margin.bottom) + 'px')

					// Scale 1:1 within parent;
					.attr('viewbox', viewbox)

					// Global rendering quality;
					.attr('preserveAspectRatio', 'xMidYMid')
					.attr('shapeRendering', 'optimizeQuality')

					// Additional;
					.attr('version', '1.1')
					.attr('pointer-events', 'all');

				var svg =
					outer.append('svg:g')
						.call(d3.behavior.zoom().on('zoom', rescale))
						.append('svg:g')
						//.on('mousemove', mousemove)
						.on('mousedown', mousedown)
						//.on('mouseup', mouseup)
						;

				// rescale g
				function rescale() {
					// var trans: any = d3.event.translate
					//   , scale: any = d3.event.scale;

					// svg.attr('transform',
					// 	'translate(' + trans + ')'
					// 	+ ' scale(' + scale + ')');
				}

				function mousedown() {
					// if (!mousedown_node && !mousedown_link) {
					// 	// allow panning if nothing is selected
					// 	svg.call(d3.behavior.zoom().on('zoom'), rescale);
					// 	return;
					// }
				}

				function resetMouseVars() {
					mousedown_node = null;
					mouseup_node = null;
					mousedown_link = null;
				}

				var update = function() {
					console.log('in createDirectedGraph update method');

					// Load graph (nodes,links) json from /graph endpoint;
					force
						.nodes(nodes)
						.links(links)
						.on('tick', tick)
						.start();

					res = new Array();

					links.forEach(
						function(d) {
							res.push(d.type);
						});

					// Define links;
					var link = svg.selectAll('.link');

					// Create relationship direction arrows;
					svg.append('defs')
						.selectAll('marker')
						.data(res)
						.enter()
						.append('marker')
						.attr('id', function(d: any) { return d; })
						.attr('viewBox', '0 -5 10 10')
						.attr('refX', function(d) { return (d.label == 'Company') ? 70 : 50; })
						.attr('refY', -0.5)
						.attr('markerWidth', 10)
						.attr('markerHeight', 6)
						.attr('orient', 'auto')
						.append('svg:path')
						.attr('d', 'M0,-5L10,0L0,5');

					// Render relationships as lines;
					link =
						svg.append('svg:g')
							.attr('id', 'line')
							.selectAll('path')
							.data(links)
							.enter()
							.append('svg:path')
							.attr('class', function(d) { return 'link ' + d.type; })
							.attr('id', function(d, i) { return 'linkId_' + i; })

							// Append relationship direction arrows;
							.attr('marker-end', function(d) { return 'url(#' + d.type + ')'; });

					// Add link line labels;
					var linkText =
						svg.append('svg:g')
							.attr('id', 'line-label-holders')
							.selectAll('g.linklabelholder')
							.data(links);

					// Blockout link line;
					linkText
						.enter()
						.append('g')
						.attr('id', 'linktext')
						.attr('class', 'linklabelholder')
						.append('text')
						.attr('class', 'linklabel')
						.attr('dy', '3')
						.style('font-size', '8px')
						.style('text-anchor', 'middle')
						.style('fill', 'transparent')
						.style('stroke', '#FFF')
						.style('stroke-width', '4.5px')
						.append('textPath')
						.attr('xlink:href', function(d, i) { return '#linkId_' + i; })
						.attr('startOffset', '50%')
						.text(function(d) { return relationshipLinkLabelFor(d.type); });

					// Show link text;
					linkText
						.enter()
						.append('g')
						.attr('id', 'linktext')
						.attr('class', 'linklabelholder')
						.append('text')
						.attr('class', 'linklabel')
						.attr('dy', '3')
						.style('font-size', '8px')
						.style('text-anchor', 'middle')
						.style('fill', '#000')
						.attr('text-rendering', 'geometricPrecision')
						.append('textPath')
						.attr('xlink:href', function(d, i) { return '#linkId_' + i; })
						.attr('startOffset', '50%')
						.text(function(d) { return relationshipLinkLabelFor(d.type); });

					// Colors;
					var get_color =
						{
							'Company': '#4DBCC6',
							'Company_Contact': '#DACA83',
							'JLL_Contact': '#E36307'
						};

					// Prevent context menu from appearing when
					// right-clicking on nodes;
					var isContextMenuVisible = false;

					// Render nodes as circles, css-class from label;
					var node =
						svg.selectAll('g.node')
							.data(nodes, function(d) {
								return d.id;
							});

					var nodeEnter = node.enter().append('g')
						.attr('class', 'node')
						.call(force.drag);

					// Append nodes;
					var nodeShape = nodeEnter.append('svg:circle')
						.attr('r', function(d) {
							return (d.label == 'Company') ? compRadius : nodeRadius;
						})
						.attr('id', function(d) { return 'node' + d.id; })
						.attr('class', 'nodeStrokeClass')
						.attr('data-type', function(d) { return d.label })
						.attr('fill', function(d) { return get_color[d.label] })
						.on('mouseover', connectedNodes)
						// .on('mousedown', function(d) {
						// 		// Disable zoom;
						// 		//svg.call(d3.behavior.zoom().on('zoom'), null);
						// })
						// .on('mouseup', function(d) {
						// 	if (mousedown_node) {
						// 		mouseup_node = d;
						// 		if (mouseup_node == mousedown_node) { resetMouseVars(); return; }

						// 		// Enable zoom
						// 		svg.call(d3.behavior.zoom().on('zoom'), rescale);
						// 	}
						// })
						.on('click', requestNodeExpandCollapse)
						// .on('mouseout',
						// 	function(d) {div.transition().style('opacity',0)
						// })
						.on('contextmenu',
							function(d) {
								// Prevent context menu;
								d3.event.preventDefault();

								if (d.label === 'Company') {
									// if (isContextMenuVisible) {
									// 	sumTip.style('opacity', 0);
									// } else {
										sumTip.transition()
											.duration(500)
											.style('opacity', 0);
										sumTip.transition()
											.duration(200)
											.style('opacity', 1);
										sumTip.html('<div class="tip-close" onclick="$(this).parent().css(\'opacity\',0)">&times;</div><div class="tip-image placeholder-company"></div>'
											+ '<ul class="tip-list">'
											+ '<li class="tip tip-contact"><a href="#">' + d.title + '</a></li>'
											+ '<li class="tip tip-title">' + d.type + '</li>'
											+ '<li class="tip tip-phone"><span>Phone</span><a href="#"><i class="ico ico-phone"></i>01202 495076</a></li>'
											+ '<li class="tip tip-email"><span>Website</span><a href="#"><i class="ico ico-globe"></i>www.' + d.title + '.com</a></li>'
											+ '<li class="tip tip-address"><span>Address</span><a href="#">' + d.city + ', ' + d.country + '</a></li></ul>')
											.style('left', (d3.event.pageX) + 'px')
											.style('top', (d3.event.pageY - 28) + 'px');

										//isContextMenuVisible = !isContextMenuVisible;
									//}
								} else {
									// if (isContextMenuVisible) {
									// 	sumTip.style('opacity', 0);
									// } else {
										sumTip.transition()
											.duration(500)
											.style('opacity', 0);
										sumTip.transition()
											.duration(200)
											.style('opacity', 1);
										sumTip.html('<div class="tip-close" onclick="$(this).parent().css(\'opacity\',0)">&times;</div><div class="tip-image placeholder-person"></div>'
											+ '<ul class="tip-list">'
											+ '<li class="tip tip-contact"><a href="#">' + d.fname + ' ' + d.lname + '</a></li>'
											+ '<li class="tip tip-title">Vice President</li>'
											+ '<li class="tip tip-phone"><span>Phone</span><a href="#"><i class="ico ico-phone"></i>01202 495076</a></li>'
											+ '<li class="tip tip-email"><span>Email</span><a href="#"><i class="ico ico-email"></i>' + d.fname + '.' + d.lname + '@company.com</a></li>'
											+ '<li class="tip tip-address"><span>Address</span><a href="#">' + d.city + ', ' + d.country + '</a></li></ul>')
											.style('left', (d3.event.pageX) + 'px')
											.style('top', (d3.event.pageY - 28) + 'px');

										//isContextMenuVisible = !isContextMenuVisible;
									//}
								}
						})
						;

					// Append title as child of node shape;
					nodeShape.append('title')
						.text(function(d) {
							return d.nodeID;
						});

					// Append node label;
					nodeEnter.append('text')
						.attr('class', 'nodeTextClass')
						.attr('text-anchor', 'middle')
						.attr('dy', '0')
						.style('font-size', function(d) { return (d.label == 'Company') ? '10px' : '9px'; })
						.style('font-weight', function(d) { return (d.label == 'Company') ? 700 : 400;})
						.each(function(d) {
							formatNodeLabelFor(d, this);
						});

					// Center primary node;
					// nodes.forEach(
					// 	function(d) {
					// 		if (d.label == 'Company' && _.isEmpty(selID)) {
					// 			d.fixed = true;
					// 			console.log('height: ' + height + 'px; width: ' + width + 'px;');
					// 			d.x = width / 2;
					// 			d.y = height / 2;
					// 			d.px = d.x;
					// 			d.py = d.y;
					// 		}
					// 	}
					// );

					// Separation between nodes for collision prevention;
					var padding = 2
						, radius = nodeRadius;

					// Helper functions for displaying text in tick;
					function formatNodeLabelFor(anObj, anElm) {
						// ****************************************************************;
						// Returns a tspan object holding a node label formatted to
						// fit within the parent container.
						// ****************************************************************;
						var isCompany = !_.isNull(anObj.title);
						var nodePadding = 3;
						var maxWidth = 2 * (anElm.parentNode.children[0].attributes[0].value - nodePadding)
							, line = []
							, lineNumber = 0
							, tsp: any
							, tsplen: any;

						// Break into lines;
						if (!_.isNull(anObj.title)) {
							var ar1: any = anObj.title.split(' ')
								, arr: any = []
								, i : any;

							// Add check to remove double space entries;
							for (var i:any = 0; i < ar1.length; i++) {
								if (ar1[i] !== "") {
									arr.push(ar1[i]);
								}
							}

							if (arr != undefined) {
								for (i = 0; i < 2; i++) {
									tsp = d3.select(anElm).append('tspan')
										.text(arr[i])
										.attr('dy', i ? '1.2em' : '-0.3em')
										.attr('x', 0)
										.attr('text-anchor', 'middle')
										.attr('class', 'tspan' + i);

									// Ellipsis truncation test;
									tsplen = tsp.node().getComputedTextLength();
									ellipsisTruncate(arr[i], tsplen, tsp, maxWidth);
								}
							}
						} else {
							var nme = anObj.fname + ' ' + anObj.lname
							  , ar1 : any = nme.split(' ')
							  , arr : any = []
							  , i : any;

							// Add check to remove double space entries;
							for (var i:any = 0; i < ar1.length; i++) {
								if (ar1[i] !== "") {
									arr.push(ar1[i]);
								}
							}

							if (arr != undefined) {
								for (i = 0; i < arr.length; i++) {
									tsp = d3.select(anElm).append('tspan')
										.text(arr[i])
										.attr('dy', i ? '1.2em' : '-0.3em')
										.attr('x', 0)
										.attr('text-anchor', 'middle')
										.attr('class', 'tspan' + i);

									// Ellipsis truncation test;
									tsplen = tsp.node().getComputedTextLength();
									ellipsisTruncate(arr[i], tsplen, tsp, maxWidth);
								}
							}
						}

						// Ellipsis truncation helper function;
						function ellipsisTruncate(aLine, aTextWidth, aTspan, aWidth) {
							var textWidth = aTextWidth  // Width of text in pixel.
								, initialText = aLine     // Initial text.
								, text = initialText
								, output = initialText
								, i = 10;

							if (aTextWidth > aWidth) {
								// Initial text is too wide, so shorten;
								var textLength = initialText.length;
								while (i > 0 && textLength > 3 && Math.abs(aWidth - textWidth) >= 0) {
									output = (textWidth >= aWidth) ? output.slice(0, -textLength * 0.15) : output.slice(0, textLength * 1.15);;
									aTspan.text(output);
									textWidth = aTspan.node().getComputedTextLength();
									textLength = output.length;
									i--;
								}

								output = output.slice(0, -3);
								output = output + '\u2026';
							}
							aTspan.text(output);
						}
					}

					// Return friendly labels for links;
					function relationshipLinkLabelFor(aType) {
						// ******************************************;
						// Return a relationship display name;
						// Internationalize later;
						// ******************************************;
						if (aType === 'KNOWS') { return 'KNOWS'; }
						if (aType === 'WORKS_AT') { return 'WORKS AT'; }
						if (aType === 'JLL_CONTACT_FOR_COMPANY') { return 'JLL PRIMARY CONTACT'; }
						if (aType === 'JLL_CONTACT') { return 'JLL CONTACT'; }
					}

					function isFiniteNumber(x) {
						// ****************************************************************;
						// Used by line text rotation;
						// ****************************************************************;
						return typeof x === 'number' && (Math.abs(x) < Infinity);
					}

					function isVector(v) {
						// ****************************************************************;
						// Used by line text rotation;
						// ****************************************************************;
						return isFiniteNumber(v.x) && isFiniteNumber(v.y);
					}

					// Minimize node overlap and collisions;
					function collide(alpha) {
						var quadtree = d3.geom.quadtree(nodes);
						return function(d) {
							var rb = 2 * radius + padding,
								nx1 = d.x - rb,
								nx2 = d.x + rb,
								ny1 = d.y - rb,
								ny2 = d.y + rb;

							quadtree.visit(function(quad, x1, y1, x2, y2) {
								if (quad.point && (quad.point !== d)) {
									var x = d.x - quad.point.x,
										y = d.y - quad.point.y,
										l = Math.sqrt(x * x + y * y);
									if (l < rb) {
										l = (l - rb) / l * alpha;
										d.x -= x *= l;
										d.y -= y *= l;
										quad.point.x += x;
										quad.point.y += y;
									}
								}
								return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
							});
						};
					}

					// Animate chart based upon physics model;
					function tick() {
						link.attr(
							'd',
							function(d) {
								return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y;
							});

						node.attr(
							'transform',
							function(d) {
								return 'translate(' + d.x + ',' + d.y + ')';
							});

						// nodes[0].x = width / 2;
						// nodes[0].y = height / 2;
						// Center primary node;
						// nodes.forEach(
						// 	function(d) {
						// 		if (d.label == 'Company' && _.isEmpty(selID)) {
						// 			d.fixed = true;
						// 			d.x = width / 2;
						// 			d.y = height / 2;
						// 		}
						// 	}
						// );

						// var k = .01;
						// nodes[0].y += (height / 2 - nodes[0].y) * k;
						// nodes[0].x += (width / 2 - nodes[0].x) * k;

						// text.attr(
						// 	'transform',
						// 	function(d) {
						// 		return 'translate(' + d.x + ',' + d.y + ')';
						// 	});

						// Handle link text positioning and right-side up rotation;
						linkText.attr('transform', function(d) {
							// Checks just in case, especially useful at the start of the sim
							if (!(isVector(d.source) && isVector(d.target))) {
								return '';
							}

							// Get the geometric center of the text element
							var box = this.getBBox();
							var center = {
								x: box.x + box.width / 2,
								y: box.y + box.height / 2
							};

							// Get the direction of the link along the X axis
							var dx = d.target.x - d.source.x;

							// Flip the text if the link goes towards the left
							return dx < 0
								? ('rotate(180 '
									+ center.x
									+ ' ' + center.y
									+ ')')
								: '';

						});

						// Minimize collisions;
						node.each(collide(0.5));
					}

					// ---------------------------------------------;
					// HIGHLIGHT NODES;
					// ---------------------------------------------;
					// Node group selection;
					var toggle = 0;

					// Build collection of associated nodes;
					var linkedByIndex = {};
					for (var i = 0; i < nodes.length; i++) {
						linkedByIndex[i + ',' + i] = 1;
					};

					links.forEach(function(d) {
						linkedByIndex[d.source.index + ',' + d.target.index] = 1;
					});

					// Find if a given pair of nodes are neighbors;
					function neighboring(a, b) {
						return linkedByIndex[a.index + ',' + b.index];
					}

					// Dim non-selected nodes;
					function connectedNodes() {
						if (toggle == 0) {
							// Dim other node groups;
							var d = d3.select(this).node().__data__;
							node.style('opacity', function(o) {
								return neighboring(d, o) || neighboring(o, d) ? 1 : 0.2;
							});

							link.style('opacity', function(o) {
								return d.index == o.source.index || d.index == o.target.index ? 1 : 0.1;
							});

							linkText.style('opacity', function(o) {
								return d.index == o.source.index || d.index == o.target.index ? 1 : 0.1;
							});
							// Update toggle;
							toggle = 1;
						} else {

							// Restore opacity;
							node.style('opacity', 1);
							link.style('opacity', 1);
							linkText.style('opacity', 1);

							// Update toggle;
							toggle = 0;
						}
					}

					// Restart force;
					force.charge(-520)
						.linkDistance(linkDistance)
						.size([width, height])
						.linkStrength(0.1)
						.start();

					return true;
					// -------------------------------------------------;
				} // END update();

				return {
					update: update
				}
			} // END createGraph();

			function init() {

				graph = new createGraph('#graph');

				var qry = constructInitialQuery()
				  , res = fetchInitialData(qry);

				if (res === false) {
					// Try minimal query with base object;
					var sQry = constructSimpleQuery()
						, sRes = fetchSimpleData(sQry);

					if (sRes === false) {
						// No matching data at all;
						console.log('no results');
					}
				}

				keepNodesOnTop();
			}

			init();

			function newNodeRefresh() {
				graph = new createGraph('#graph');
				keepNodesOnTop();
			}

			// -------------------------------------------------;
			// AJAXIAN DATA ACCESS: PARSING;
			// -------------------------------------------------;
			// Parse expanded node data;
			function parseExpandResponse(data, aSelectedNodeId) {
				// *******************************************************;
				// Parse expansion data structure for selected node;
				// *******************************************************;
				data.results[0].data.forEach(
					function(row) {
						row.graph.nodes.forEach(function(n) {
							if (idIndex(nodes, n.id) == null) {
								if (n.labels[0] === 'Company') {
									nodes.push(
										{
											id: n.id,
											label: n.labels[0],
											title: n.properties.CompanyName,
											type: n.properties.CompanyType,
											address: n.properties.Address,
											city: n.properties.City,
											country: n.properties.Country,
											region: n.properties.Region,
											post: n.properties.PostalCode,
											contacts: n.properties.CompanyContactsCount,
											nodeID: n.properties.CompanyID
										});
								}
								else {
									nodes.push(
										{
											id: n.id,
											label: n.labels[0],
											title: null,
											fname: n.properties.FirstName,
											lname: n.properties.LastName,
											city: n.properties.City,
											country: n.properties.Country,
											nodeID: n.properties.ContactID
										});
								}
							}
						});

						links = links.concat(
							row.graph.relationships.map(
								function(r) {
									return {
										source: idIndex(nodes, r.startNode),
										target: idIndex(nodes, r.endNode),
										type: r.type,
										value: 1
									};
								})
						);
					}); // END: Row and forEach;
				console.log('expansion node: ' + nodes[0].fname + ' ' + nodes[0].lname);
				console.log('expansion link src: ' + links[0].source + ' target: ' + links[0].target + ' type: ' + links[0].type);
				console.log(selID);
				buildNodeFor(aSelectedNodeId);
			}

			// Parse initial data for display;
			function parseInitialResponse(aDataResponse) {
				// *******************************************************;
				// Parse initial data structure based on user query;
				// *******************************************************;
				aDataResponse.data.results[0].data.forEach(
					function(row) {
						row.graph.nodes.forEach(function(n) {
							if (idIndex(nodes, n.id) == null) {
								if (n.labels[0] === 'Company') {
									nodes.push(
										{
											id: n.id,
											label: n.labels[0],
											title: n.properties.CompanyName,
											type: n.properties.CompanyType,
											address: n.properties.Address,
											city: n.properties.City,
											country: n.properties.Country,
											region: n.properties.Region,
											post: n.properties.PostalCode,
											contacts: n.properties.CompanyContactsCount,
											nodeID: n.properties.CompanyID
										});
								}
								else {
									nodes.push(
										{
											id: n.id,
											label: n.labels[0],
											title: null,
											fname: n.properties.FirstName,
											lname: n.properties.LastName,
											city: n.properties.City,
											country: n.properties.Country,
											nodeID: n.properties.ContactID
										});
								}
							}
						});

						links = links.concat(
							row.graph.relationships.map(
								function(r) {
									return {
										source: idIndex(nodes, r.startNode),
										target: idIndex(nodes, r.endNode),
										type: r.type,
										value: 1
									};
								})
						);

					});
				var res = {
					nodes : nodes,
					links : links
				}
				console.log('main data should be created: ' + res.nodes[0].fname + ' ' + res.nodes[0].lname);

				return graph.update();
			}

			// -------------------------------------------------;
			// AJAXIAN DATA ACCESS: RETRIEVAL;
			// -------------------------------------------------;
			// Fetch expanded node and relationship data from server;
			function fetchExpandData( aSelectedNodeId, aType ) {
				$.ajax({
					url: 'http://jllcontactnet.cloudapp.net:7474/db/data/transaction/commit',
					headers: { 'Authorization': auth },
					dataType: 'json',
					type: 'POST',
					data: JSON.stringify(constructExpansionQuery( aSelectedNodeId, aType )),
					contentType: 'application/json; charset=utf-8',
					success: function(data, textStatus, jqXHR) {
						console.log('Expansion Query for ' + aSelectedNodeId + ' executed.')
						parseExpandResponse(data, aSelectedNodeId);
					}
				});
			}

			// Fetch initial node and relationship data from server;
			function fetchInitialData(aQuery) {
				//var qry: any = { 'statements': [{ 'statement': 'MATCH (c:Company) RETURN DISTINCT c.Country as countryName ORDER BY c.Country', 'resultDataContents': ['row'] }] };
				var qry: any = aQuery;

				return dbRequest.getData(qry).then(
					function(dataResponse) {
						if (dataResponse.data.results[0].data.length === 0) {
							// No initial results, so return false;
							console.log('Promise was fulfilled but empty');
							return parseInitialResponse(fetchSimpleData(constructSimpleQuery()));
						} else {
							console.log('Promise was fulfilled');
							// Format and return data;
							return parseInitialResponse(dataResponse);
						}
					},
					function(error) {
						// The Promise was rejected.
						console.log(error + ': Promise was rejected');
						return error;
					});
			}

			// Fetch simple node data from server;
			function fetchSimpleData(aQuery) {
				var qry: any = aQuery;

				return dbRequest.getData(qry).then(
					function(dataResponse) {
						if (dataResponse.data.results[0].data.length === 0) {
							// No results at all, so update page and return false;
							var noResultsStr = '<p class="no-results-msg">No matches found for your search. Please <a ng-click="clearRefiners()">clear your filters</a> or enter new search criteria.</p>';
							angular.element(
								document.getElementById('graph')).html('').append($compile(noResultsStr)($scope));
							return false;
						} else {
							console.log('Promise was fulfilled: ' + dataResponse.data.results[0].data[0].graph.nodes[0].id);
							// Format and return data;
							return parseInitialResponse(dataResponse);
						}
					},
					function(error) {
						// The Promise was rejected.
						console.log(error + ': Promise was rejected');
						return error;
					});
			}

			// -------------------------------------------------;
			// AJAXIAN DATA ACCESS: QUERIES;
			// -------------------------------------------------;
			// Node Expansion Data Query;
			function constructExpansionQuery( aSelectedNodeId, aType ) {
				// *******************************************************;
				// This uses all current search criteria with an
				// additional constraint of matching the selected node id
				// and a domain object type;
				// *******************************************************;
				var aSelId = parseInt(aSelectedNodeId);

				if (aType === 'Company') {
					// Given a company ID, expand to show all contacts and
					// associated JLL contacts;
					return { 'statements': [{ 'parameters': { 'aSelectedNodeId': aSelId }, 'statement': 'MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact)<-[m:KNOWS]-(jll:JLL_Contact) WHERE c.CompanyID = {aSelectedNodeId} RETURN c,r,cc,m LIMIT 100', 'resultDataContents': ['graph'] }] };
				};

				if (aType === 'Company_Contact') {
					// Given a Company Contact, expand to show all companies
					// and associated JLL contacts;
					return { 'statements': [{ 'parameters': { 'aSelectedNodeId': aSelId }, 'statement': 'MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact)<-[m:KNOWS]-(jll:JLL_Contact) WHERE  cc.ContactID = {aSelectedNodeId} RETURN c,r,cc,m LIMIT 100', 'resultDataContents': ['graph'] }] };
				}

				if (aType === 'JLL_Contact') {
					// Given a JLL Contact, show all other contacts they know
					// and their associated companies;
					return { 'statements': [{ 'parameters': { 'aSelectedNodeId': aSelId }, 'statement': 'MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact)<-[m:KNOWS]-(jll:JLL_Contact) WHERE  jll.ContactID = {aSelectedNodeId} RETURN c,r,cc,m LIMIT 100', 'resultDataContents': ['graph'] }] };
				}
			}

			// Initial Graph Data Query;
			function constructInitialQuery() {
				// *******************************************************;
				// This uses major criteria for initial search;
				// Refiner criteria filters this data unless major
				// criteria are reset;
				// *******************************************************;
				var aFindTxt = getFormattedParamFor($scope.selected)
				  , countryName = getFormattedParamFor($scope.ddSelectSelected.value)
				  , aFindTyp = $scope.selectedFindType.value;

				if (aFindTyp === 'Company') {
					return {
						'statements': [{
							'parameters': { 'aCountry': countryName, 'aFindTxt': aFindTxt }, 'statement': 'MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact)<-[m:KNOWS]-(jll:JLL_Contact), (c)-[q:JLL_CONTACT_FOR_COMPANY]->(jll) WHERE c.Country =~ {aCountry} AND c.CompanyName =~ {aFindTxt} RETURN c, COLLECT(q), COLLECT(cc), COLLECT(m), COLLECT(jll)', 'resultDataContents': ['graph']
						}]
					};
				} else {
					// Split contact find text at ', ' for selected data;
					var nme = aFindTxt.split(/[\s,]+/)
					  , lnm
					  , fnm;

					if (nme.length > 1) {

						};
					return { 'statements':
						[
							{ 'parameters':
								{
									'aCountry': countryName,
									'aFindTxt': aFindTxt
								},
							'statement': 'MATCH (c:Company)<-[r:WORKS_AT]-(cc:Company_Contact)<-[m:KNOWS]-(jll:JLL_Contact), (c)-[q:JLL_CONTACT_FOR_COMPANY]->(jll) WHERE c.Country =~ {aCountry} AND cc.FirstName =~ {aFindTxt} OR cc.LastName =~ {aFindTxt} RETURN c, COLLECT(q), COLLECT(cc), COLLECT(m), COLLECT(jll)',
								'resultDataContents': ['graph']
							}
						]
					};
				}
			}

			// Simple Graph Data Query;
			function constructSimpleQuery() {
				// *******************************************************;
				// This uses major criteria for initial search;
				// Refiner criteria filters this data unless major
				// criteria are reset;
				// *******************************************************;
				var aFindTxt = getFormattedParamFor($scope.selected)
				  , countryName = getFormattedParamFor($scope.ddSelectSelected.value)
				  , aFindTyp = $scope.selectedFindType.value;

				if (aFindTyp === 'Company') {
					return { 'statements': [{ 'parameters': { 'aCountry': countryName, 'aFindTxt': aFindTxt }, 'statement': 'MATCH (c:Company) WHERE c.Country =~ {aCountry} AND c.CompanyName =~ {aFindTxt} RETURN c', 'resultDataContents': ['graph'] }] };
				} else {
					return {
						'statements':
						[
							{
								'parameters':
								{
									'aCountry': countryName,
									'aFindTxt': aFindTxt
								},
								'statement': 'MATCH (cc:Company_Contact) WHERE cc.Country =~ {aCountry} AND (cc.FirstName =~ {aFindTxt} OR cc.LastName =~ {aFindTxt}) RETURN cc',
								'resultDataContents': ['graph']
							}
						]
					};
				}
			}

			// -------------------------------------------------;
			// NODE EXPANSION;
			// -------------------------------------------------;
			// Ask for a node to be expanded;
			function requestNodeExpandCollapse() {
				// *******************************************************;
				// Only make the call to build out the node on
				// first click; otherwise, expand or collapse
				// current node using existing data;
				// *******************************************************;
				// Get clicked node;
				var aNode = d3.select(this);

				// See if node has been clicked before;
				var clckd = aNode.classed('clicked');

				// If first click, fetch data; otherwise
				// show or collapse the current data;
				if (!clckd) {
					var aSelectedNodeId = aNode.select('title').text(),
						aDataType = aNode.attr('data-type');
						selID = aSelectedNodeId;
						console.log('selID from request expand: ' + selID);

					// Right now, only allow JLL contacts
					// to have expansion capability;
					//if (aDataType === 'JLL_Contact') {
						expandNodeFor(aSelectedNodeId, aDataType);
						aNode.classed('clicked', true);
					//}

				} else {
					// Stub for expand / collapse functionality
				}
			}

			function expandNodeFor(aSelectedNodeId, aType) {
				fetchExpandData(aSelectedNodeId, aType);
			}

			function buildNodeFor(aSelectedNodeId) {
				addNodes();
			//	keepNodesOnTop();
			}


			// ---------------------------------------------;
			// DOM MANAGEMENT;
			// ---------------------------------------------;
			// Reorder the DOM to put the svg:g on top;
			function keepNodesOnTop() {
				$('.nodeStrokeClass').each(
					function(index) {
						var gnode = this.parentNode;
						gnode.parentNode.appendChild(gnode);
					});
			}

			function addNodes() {
				d3.select('svg').remove();
				newNodeRefresh();
				graph.update();
			}

			function startDisplay() {
				// path = path.data(force.links(), function(d) { return d.source.id + '-' + d.target.id; });
				// path.enter().insert('line', '.node').attr('class', 'link');
				// path.exit().remove();
				// link: any = link.data(force.links(), function(d) { return d.source.id + '-' + d.target.id; });
				// link.enter().insert('line', '.node').attr('class', 'link');
				// link.exit().remove();

				// node.data(force.nodes(), function(d) { return d.id; });

				// node.enter()
				// 	.append('circle')
				// 	.attr('class', function(d) { return 'node ' + d.label; })
				// 	.attr('r', 25)
				// 	.attr('fill', function(d) { return get_color[d.label] })
				// 	.exit()
				// 	.remove();

				//force.start();
			}


			// ---------------------------------------------;
			// NODE MANAGEMENT;
			// ---------------------------------------------;
			var addNode = function(anId) {

				//nodes.push({'id':id});
				//update();
			};

			var addLink = function(source, target, value) {
				links.forEach(
					function(r) {
						links.push(
							{
								'source': findNode(r.source),
								'target': findNode(r.target),
								'type': r.type
							})
					});
				//links.push({'source':findNode(source),'target':findNode(target),'value':value});
				//update();
			};

			var findNode = function(id) {
				for (var i in nodes) {
					if (nodes[i]['id'] === id) return nodes[i];
				};
			};

			var findNodeIndex = function(id) {
				for (var i = 0; i < nodes.length; i++) {
					if (nodes[i].id == id) {
						return i;
					}
				};
			};

			this.addTestNode = function() {
				var a = {
					id: '3',
					label: 'Company_Contact',
					fname: 'Joe',
					lname: 'Doakes'
								},
					b = {
						id: 'node4632'
					};
				nodes.push(a, b);
				links.push({ source: a, target: b });
				startDisplay();
			}
		// ---------------------------------------------------;
		}; // END: drawDirectedGraphWith(data);

		// Clear all search refiners;
		this.vm.clearRefiners = function() {
			if ($scope.ddSelectSelected.value == 'United Kingdom') {
				$scope.tagsSourceSystem = [{ text: 'Client First (1,618)' }];
			}
			else {
				$scope.tagsSourceSystem = [];
			}

			if ($scope.ddSelectSelected.value == 'United Kingdom') {
				$scope.tagsRegion = [{ text: 'EMEA (15,475)' }];
			}
			else {
				$scope.tagsRegion = [];
			}

			if ($scope.ddSelectSelected.value == 'United Kingdom') {
				$scope.tagsCountry = [{ text: 'United Kingdom' }];
			}
			else {
				$scope.tagsCountry = [];
			}

			$scope.tagsIndustry = [];
			$scope.companyType = [];
		}

		$scope.searchFor = function() {
			d3.select('svg').remove();
			drawDirectedGraph();
		}

		this.activate();
	}

	public showDirectedGraph() {
		this.vm.showDirectedGraph();
		}

	public addNode() {
		this.vm.drawDirectedGraph.addNode();
	}

	public countryClick() {
		this.vm.countryClick();
		}

	public clearRefiners() {
		this.vm.clearRefiners();
	}

	public activate() {
		this.vm.showDirectedGraph();
		}
}

angular
	.module('app.results', [])
	.controller('resultsController', ResultsController)
	.directive(
		'companyContactsCount',
		[
		  '$parse'
		 , '$compile'
		 , function($compile) {
			 	function link(scope, element, $parse, attrs) {
					var auth = 'Basic ' + btoa('neo4j:mnbv!456')
					  , aFindTxt = '(?i).*' + scope.selected + '.*'
					  , countryName = '(?i).*' + scope.ddSelectSelected.value + '.*'
					  , aFindTyp = '(?i).*' + scope.selectedFindType.value + '.*'
						, subtot
					  , ctotal
						;

					function getTotalQuery() {

						if (aFindTyp === 'Company') {
							return { 'statements': [{ 'parameters': { 'aCountry': countryName, 'aFindTxt': aFindTxt }, 'statement': 'MATCH (c:Company) WHERE c.Country =~ {aCountry} RETURN c', 'resultDataContents': ['rows'] }] };
						} else {
							return {'statements':[{'parameters':{'aCountry': countryName,'aFindTxt': aFindTxt},'statement': 'MATCH (cc:Company_Contact) WHERE cc.Country =~ {aCountry} RETURN cc','resultDataContents': ['rows']}]};
						}
					}

					function getFindQuery() {
						if (aFindTyp === 'Company') {
							return { 'statements': [{ 'parameters': { 'aCountry': countryName, 'aFindTxt': aFindTxt }, 'statement': 'MATCH (c:Company) WHERE c.Country =~ {aCountry} AND c.CompanyName =~ {aFindTxt} RETURN COUNT(DISTINCT c)', 'resultDataContents': ['row'] }] };
						} else {
							return {'statements':[{'parameters':{'aCountry': countryName,'aFindTxt': aFindTxt},'statement': 'MATCH (cc:Company_Contact) WHERE cc.Country =~ {aCountry} AND (cc.FirstName =~ {aFindTxt} OR cc.LastName =~ {aFindTxt}) RETURN COUNT(DISTINCT cc)','resultDataContents': ['row']}]};
							}
					}

					function getTotalCount(qry) {
						$.ajax({
							url: 'http://jllcontactnet.cloudapp.net:7474/db/data/transaction/commit',
							headers: { 'Authorization': auth },
							dataType: 'json',
							type: 'POST',
							data: JSON.stringify(qry),
							contentType: 'application/json; charset=utf-8',
							success: function(data, textStatus, jqXHR) {
								return data.results[0].data[0].row[0];
							}
						});
					}

					function getFindCount(qry) {
						$.ajax({
							url: 'http://jllcontactnet.cloudapp.net:7474/db/data/transaction/commit',
							headers: { 'Authorization': auth },
							dataType: 'json',
							type: 'POST',
							data: JSON.stringify(qry),
							contentType: 'application/json; charset=utf-8',
							success: function(data, textStatus, jqXHR) {
								element.text(data.results[0].data[0].row[0]);
								console.log(subtot);
							}
						});
					}
					getFindCount(getFindQuery());
					getTotalCount(getTotalQuery());
				}

				return {
					link: link
				};
			}]);
